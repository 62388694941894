import { MessageService } from "common";
import { UsersRemote } from "../../infrastructure/users.remote";
import {
  EditUserData,
  UsersQueryResult,
  UserData,
} from "../models/users.model";
import { EMPTY, Observable } from "rxjs";
import {
  catchError,
  switchMap
} from "rxjs/operators";
import { HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { UsersBridgeService } from "./users-bridge.service";
import { BrokerUserData } from "projects/partner/src/app/broker/domain/models/broker.model";

@Injectable({
  providedIn: "root",
})
export class UsersFacade {
  constructor(
    private readonly usersBridgeService: UsersBridgeService,
    private readonly remote: UsersRemote,
    private messageService: MessageService,
  ) {}

  getUsers$(params: any): Observable<UsersQueryResult> {
    return this.usersBridgeService.getCurrentBroker$().pipe(
      switchMap((currentBroker: BrokerUserData) => {
        return this.remote.getUsers(currentBroker.brokerId, params);
      }),
    );
  }

  getUser$(id: number): Observable<UserData> {
    return this.remote.getUser(id);
  }

  createUser(data: EditUserData): Observable<UserData> {
    return this.remote
      .createUser(data)
      .pipe(catchError(error => this.handleCreateUserError(error)));
  }

  updateUser(
    id: number,
    data: EditUserData,
  ): Observable<UserData> {
    return this.remote.updateUser(id, data);
  }

  resendInvitation(userId: number): Observable<void> {
    return this.remote.resendInvitation(userId);
  }

  private handleCreateUserError(
    response: HttpErrorResponse,
  ): Observable<UserData> {
    if (response.status === 409) {
      this.messageService.error(
        "A broker member with that email already exists.",
      );
      return EMPTY;
    }

    this.messageService.error("Team member cannot be added.");
    return EMPTY;
  }
}
