import { Injectable } from '@angular/core';
import { ApplicationStage, MessageService } from 'common';
import { actionErrorMessage } from './not-interested-action-data';
import { BrokerStatus } from 'projects/partner/src/app/shared/SharedConstants';
import { SubmissionsFacade } from '../../domain/+state/submissions.facade';
import { NotInterestedActionCompletedData, NotInterestedActionInputData } from './not-interested-action.model';
import { catchError, map } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { NotInterestedDialogData, NotInterestedDialogResult } from '../not-interested-dialog/not-interested-dialog.model';
import { UpdateApplicationPayload } from '../../domain/models/application.model';

@Injectable()
export class NotInterestedActionFacade {
  constructor(
    private readonly facade: SubmissionsFacade,
    private readonly messageService: MessageService,
  ) { }

  public checkIfIsActionAvailable(data: NotInterestedActionInputData) {
    switch (data?.brokerStatus) {
      case BrokerStatus.PROCESSING:
      case BrokerStatus.NOT_INTERESTED:
      case BrokerStatus.OFFER:
      case BrokerStatus.CONDITIONAL_OFFER:
      case BrokerStatus.CONTRACT_READY:
      case BrokerStatus.CONTRACT_OUT:
      case BrokerStatus.CLOSING:
      case BrokerStatus.CLOSING_INCOMPLETE:
      case BrokerStatus.DRAFT:
      case BrokerStatus.SUBMISSION_INCOMPLETE:
        return true;

      default:
        return false;
    }
  }

  public updateApplicationStage(applicationId: number, updateApplicationPayload: UpdateApplicationPayload, successMessage: string): Observable<Partial<NotInterestedActionCompletedData>> {
    return this.facade.updateApplicationStage(applicationId, updateApplicationPayload)
      .pipe(map(() => {
        this.messageService.success(successMessage);
        return {
          isSuccess: true
        };
      }), catchError(() => {
        this.messageService.error(actionErrorMessage);
        return of({
          isSuccess: false,
        });
      }))
  }

  public getNotInterestedDialogData(): Observable<NotInterestedDialogData> {
    return this.facade.getNotInterestedDialogData();
  }

  public prepareUpdateApplicationPayloadFromDialogResult(result: NotInterestedDialogResult): UpdateApplicationPayload {
    const payload: UpdateApplicationPayload = {
      stage: ApplicationStage.NotInterested,
      lostReason: result.notInterestedReason,
    };

    if (result.competitorId) {
      payload.competitorId = result.competitorId;
    }

    if (result.additionalComment) {
      payload.lostReasonAdditionalComments = result.additionalComment;
    }

    return payload;
  }
}
