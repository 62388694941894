import { Component, Inject } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ButtonColor } from 'common';
import { Observable } from 'rxjs';
import { NotInterestedDialogData, NotInterestedDialogResult } from './not-interested-dialog.model';
import { LostReason } from '../../../dictionaries/domain/model/dictionaries.model';
import { Competitor } from '../../../competitors/domain/model/competitors.model';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

@UntilDestroy()
@Component({
  selector: 'ifbp-not-interested-dialog',
  templateUrl: './not-interested-dialog.component.html',
  styleUrls: ['./not-interested-dialog.component.scss']
})
export class NotInterestedDialogComponent {
  readonly ButtonColor = ButtonColor;
  form: UntypedFormGroup;
  notInterestedReasons: LostReason[];
  competitors: Competitor[];
  isCompetitorVisible: boolean;
  isCommentVisible: boolean;

  constructor(
    public dialogRef: MatDialogRef<NotInterestedDialogComponent>,
    private formBuilder: UntypedFormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: NotInterestedDialogData
  ) {
    this.notInterestedReasons = this.data.notInterestedReasons;
    this.competitors = this.data.competitors;
    this.isCommentVisible = false;
    this.isCompetitorVisible = false;

    this.form = this.formBuilder.group({
      notInterestedReason: new UntypedFormControl(
        undefined,
        { validators: [Validators.required] }),
      competitorId: undefined,
      additionalComment: undefined
    });
  }

  public ngOnInit(): void {
    this.form.valueChanges.pipe(
      distinctUntilChanged(),
      debounceTime(300),
      untilDestroyed(this)
    ).subscribe(formData => {
        this.setVisibility(formData);
      });
  }

  public static show(dialog: MatDialog, data: NotInterestedDialogData):
    Observable<NotInterestedDialogResult> {
    return dialog
      .open(NotInterestedDialogComponent, {
        data: { ...data },
        closeOnNavigation: true,
        width: "500px",
        autoFocus: false,
      })
      .afterClosed();
  }

  private setVisibility(formData: NotInterestedDialogResult) {
    if (!formData?.notInterestedReason) {
      return;
    }
    const currentNotInterestedReason = this.data.notInterestedReasons
      .find(notInterested => notInterested.key === formData.notInterestedReason)
    if (!currentNotInterestedReason) {
      return;
    }
    this.isCompetitorVisible = currentNotInterestedReason.isCompetitorVisible;
    this.setControl('competitorId', this.isCompetitorVisible, true);
    this.isCommentVisible = currentNotInterestedReason.isCommentVisible;
    this.setControl('additionalComment', this.isCommentVisible, true);
  }

  private setControl(controlName: string, isEnabled: boolean, isRequired: boolean) {
    const control = this.form.get(controlName);
    if (isEnabled) {
      control?.enable({ emitEvent: false });
    } else {
      control?.disable({ emitEvent: false });
      control?.patchValue(undefined, { emitEvent: false });
    }
    if (isRequired && isEnabled) {
      control?.setValidators(Validators.required);
    } else {
      control?.clearValidators();
    }
  }
}
